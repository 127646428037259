body {
  overflow-x: hidden;
}

#skills {
  transform: translateY(100vh);
}

#projects {
  transform: translateY(100vh);
}

#contact {
  transform: translateY(100vh);
}

// Declare custom font for pong score
@font-face {
  font-family: "Pixel";
  src: url("assets/fonts/pixel.ttf");
}
