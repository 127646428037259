.container {
  height: auto;
  padding: 75px 0px;
  background: #151515;
  color: #fff;
  text-align: center;
  @media (max-width: 1000px) {
    height: auto;
    padding-bottom: 100px;
    padding-top: 0;
  }
}

.nameInput {
  width: 400px;
  height: 50px;
  border: none;
  border-bottom: solid 1px #707070;
  color: #fff;
  font-family: "Fira Sans", sans-serif;
  font-size: 30px;
  background: none;
  font-weight: 200;
  margin-right: 50px;
  transition: border-color 0.4s ease;
  @media (max-width: 1000px) {
    display: block;
    margin: auto;
    width: 90vw;
    margin-bottom: 50px;
    font-size: 25px;
  }
  &:focus {
    outline: none;
    border-color: #0080ff;
  }
}

.emailInput {
  width: 400px;
  height: 50px;
  border: none;
  border-bottom: solid 1px #707070;
  color: #fff;
  font-family: "Fira Sans", sans-serif;
  font-size: 30px;
  background: none;
  font-weight: 200;
  margin-left: 50px;
  transition: border-color 0.4s ease;
  @media (max-width: 1000px) {
    display: block;
    margin: auto;
    width: 90vw;
    font-size: 25px;
  }
  &:focus {
    outline: none;
    border-color: #0080ff;
  }
}

.messageInput {
  width: 800px;
  height: 50px;
  background: none;
  border: solid 1px #707070;
  color: #fff;
  font-family: "Fira Sans", sans-serif;
  font-size: 25px;
  font-weight: 200;
  margin: auto;
  margin-top: 50px;
  display: block;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 140px;
  padding-right: 90px;
  margin-top: 5px;
  transition: border-color 0.5s ease-in-out;
  @media (max-width: 1000px) {
    display: block;
    margin: auto;
    width: 90vw;
    padding: 0;
    padding-bottom: 150px;
    font-size: 20px;
  }
  &:focus {
    outline: none;
    border-color: #0080ff;
  }
}
