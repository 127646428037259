body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
  background: #111; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

a {
  text-decoration: none;
  color: inherit; }

body {
  overflow-x: hidden; }

#skills {
  -webkit-transform: translateY(100vh);
          transform: translateY(100vh); }

#projects {
  -webkit-transform: translateY(100vh);
          transform: translateY(100vh); }

#contact {
  -webkit-transform: translateY(100vh);
          transform: translateY(100vh); }

@font-face {
  font-family: "Pixel";
  src: url(/static/media/pixel.5687fe87.ttf); }

.Contact_container__1j_a8 {
  height: auto;
  padding: 75px 0px;
  background: #151515;
  color: #fff;
  text-align: center; }
  @media (max-width: 1000px) {
    .Contact_container__1j_a8 {
      height: auto;
      padding-bottom: 100px;
      padding-top: 0; } }

.Contact_nameInput__19wFS {
  width: 400px;
  height: 50px;
  border: none;
  border-bottom: solid 1px #707070;
  color: #fff;
  font-family: "Fira Sans", sans-serif;
  font-size: 30px;
  background: none;
  font-weight: 200;
  margin-right: 50px;
  -webkit-transition: border-color 0.4s ease;
  transition: border-color 0.4s ease; }
  @media (max-width: 1000px) {
    .Contact_nameInput__19wFS {
      display: block;
      margin: auto;
      width: 90vw;
      margin-bottom: 50px;
      font-size: 25px; } }
  .Contact_nameInput__19wFS:focus {
    outline: none;
    border-color: #0080ff; }

.Contact_emailInput__KQxRc {
  width: 400px;
  height: 50px;
  border: none;
  border-bottom: solid 1px #707070;
  color: #fff;
  font-family: "Fira Sans", sans-serif;
  font-size: 30px;
  background: none;
  font-weight: 200;
  margin-left: 50px;
  -webkit-transition: border-color 0.4s ease;
  transition: border-color 0.4s ease; }
  @media (max-width: 1000px) {
    .Contact_emailInput__KQxRc {
      display: block;
      margin: auto;
      width: 90vw;
      font-size: 25px; } }
  .Contact_emailInput__KQxRc:focus {
    outline: none;
    border-color: #0080ff; }

.Contact_messageInput__2QnWK {
  width: 800px;
  height: 50px;
  background: none;
  border: solid 1px #707070;
  color: #fff;
  font-family: "Fira Sans", sans-serif;
  font-size: 25px;
  font-weight: 200;
  margin: auto;
  margin-top: 50px;
  display: block;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 140px;
  padding-right: 90px;
  margin-top: 5px;
  -webkit-transition: border-color 0.5s ease-in-out;
  transition: border-color 0.5s ease-in-out; }
  @media (max-width: 1000px) {
    .Contact_messageInput__2QnWK {
      display: block;
      margin: auto;
      width: 90vw;
      padding: 0;
      padding-bottom: 150px;
      font-size: 20px; } }
  .Contact_messageInput__2QnWK:focus {
    outline: none;
    border-color: #0080ff; }

.Loader_spinner__AGBCl {
  margin: auto;
  margin-top: 50px;
  width: 70px;
  text-align: center;
}

.Loader_spinner__AGBCl > div {
  width: 14px;
  height: 14px;
  background-color: #0080ff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: Loader_sk-bouncedelay__2TR5z 1.4s infinite ease-in-out both;
  animation: Loader_sk-bouncedelay__2TR5z 1.4s infinite ease-in-out both;
}

.Loader_spinner__AGBCl .Loader_bounce1__1DUaW {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.Loader_spinner__AGBCl .Loader_bounce2__2-AIW {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes Loader_sk-bouncedelay__2TR5z {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes Loader_sk-bouncedelay__2TR5z {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

